import { Container, FormControl, Grid, MenuItem, Select } from "@material-ui/core";
import { ArrowDownwardRounded } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchMemberRequest } from "../../store/member/member.action";
import { getMembers } from "../../store/member/member.selector";
import { fetchOrderRequest } from "../../store/order/order.actions";
import TitleBar from "../base/TitleBar";
import OrderList from "./Order.list";

function Order() {
    const location = useLocation();
    const dispatch = useDispatch();
    const [mode, setMode] = useState("");
    const [member, setMember] = useState("");

    const { members } = useSelector((state) => {
        return {
            members: getMembers(state),
        }
    });

    useEffect(() => {
        if (members.length === 0) {
            dispatch(fetchMemberRequest());
        }
    }, [members])


    useEffect(() => {
        if (location.pathname) {
            let type = location.pathname.replace("/order/", "");
            setMode(type);
        }
    }, [location])

    useEffect(() => {
        if (member) {
            dispatch(fetchOrderRequest({
                type: mode,
                id: member
            }));
        }
        else {
            dispatch(fetchOrderRequest(""));
        }
    }, [mode, member])

    return (
        <div className="mt-2">
            <TitleBar heading={mode + " Order"} secondary={"Order"}
                icon={<ArrowDownwardRounded />} />
            <Grid container spacing={2} className="my-2">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Container maxWidth="sm">
                        <FormControl fullWidth variant="outlined">
                            <Select
                                margin="dense"
                                fullWidth
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={member}
                                displayEmpty
                                onChange={(e) => {
                                    setMember(e.target.value);

                                }}
                            >
                                <MenuItem value={""}>Select Member</MenuItem>
                                {
                                    members.map((x) => <MenuItem value={x.id}>{x.fullName}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Container>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <OrderList />
                </Grid>
            </Grid>
        </div>
    );
}

export default Order;
