import { MemberActionTypes, memberInitialState } from './member.model';

const reducer = (state = memberInitialState, action) => {
    switch (action.type) {
        case MemberActionTypes.FETCH_MEMBER_REQUEST:
        case MemberActionTypes.ACTIVATE_MEMBER_REQUEST:
        case MemberActionTypes.SUSPEND_MEMBER_REQUEST:
        case MemberActionTypes.APPROVE_KYC_REQUEST:
        case MemberActionTypes.REJECT_KYC_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case MemberActionTypes.FETCH_MEMBER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                members: action.payload
            };

        case MemberActionTypes.ACTIVATE_MEMBER_SUCCESS:
        case MemberActionTypes.SUSPEND_MEMBER_SUCCESS:
        case MemberActionTypes.APPROVE_KYC_SUCCESS:
        case MemberActionTypes.REJECT_KYC_SUCCESS:
            return {
                ...state,
                isLoading: false,
                members: state.members.map((s) => {
                    if (action.payload.id === s.id) {
                        s = action.payload;
                    }
                    return s;
                })
            };

        case MemberActionTypes.FETCH_MEMBER_FAILURE:
        case MemberActionTypes.ACTIVATE_MEMBER_FAILURE:
        case MemberActionTypes.SUSPEND_MEMBER_FAILURE:
        case MemberActionTypes.APPROVE_KYC_FAILURE:
        case MemberActionTypes.REJECT_KYC_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        default:
            return state;
    }
}

export { reducer as MemberReducer };