import HttpHelper from '../../helpers/httpHelper';
require('dotenv').config();
const headerInfo = {};
class AuthAPI {
    static CheckEmail(email) {
        let url = process.env.REACT_APP_API_URL + '/admin/email/' + email
        return HttpHelper.httpRequest(url, 'GET', {});
    }

    static async Login() {
        let url = process.env.REACT_APP_API_URL + '/admin/token';
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async Logout(id) {
        let url = process.env.REACT_APP_API_URL + '/logout';
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }
}

export default AuthAPI;
