import { Box, Card, CardContent, CardHeader, Chip, Container, Dialog, DialogContent, DialogTitle, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { AccountBoxSharp, AccountCircle, Close, Email, Fingerprint, PhoneAndroid, Visibility } from '@material-ui/icons';
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from "underscore";
import { IsNullOrEmpty, getTransactionUrl } from "../../helpers/utility";
import { fetchUserRequest } from '../../store/user/user.actions';
import { getCurrentUser } from '../../store/user/user.selector';
import { fetchMyWithdrawRequest } from '../../store/withdraw/withdraw.action';
import { getMyWithdraws, getWithdrawLoading } from '../../store/withdraw/withdraw.selector';
import TitleBar from '../base/TitleBar';
import WithdrawDetail from '../withdraw/WithdrawDetail';
import Loader from '../base/loader';

export default function ProfileContainer() {
    const [selectedItem, setSelectedItem] = useState("");
    const [datum, setDatum] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUserRequest());
        dispatch(fetchMyWithdrawRequest());
    }, [])

    const { currentUser, withdraws, isLoading } = useSelector((state) => {
        return {
            currentUser: getCurrentUser(state),
            withdraws: getMyWithdraws(state),
            isLoading: getWithdrawLoading(state)
        }
    }, []);

    useEffect(() => {
        if (selectedItem) {
            let z = _.find(withdraws, x => x.id === selectedItem);
            setDatum(z);
        }
        else {
            setDatum({});
        }
    }, [selectedItem])

    const actionRenderer = () => {
        return ({
            name: 'id',
            label: 'Info',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    let user = withdraws.filter((item) => {
                        return item.id === value;
                    });
                    return (
                        <Visibility
                            color="secondary"
                            onClick={() => setSelectedItem(value)}
                        />
                    );
                }
            }
        });
    };

    const hashRenderer = () => {
        return ({
            name: 'id',
            label: 'Transaction Hash',
            options: {
                setCellProps: () => ({
                    align: "center"
                }),
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    let item = _.find(withdraws, s => s.id === value)
                    return (
                        item.transactionHash ? <a target='_blank' href={getTransactionUrl(item.coinType) + item.transactionHash}>Click</a> : <></>
                    );
                }
            }
        });
    };

    const statusRenderer = () => {
        return ({
            name: 'status',
            label: 'Status',
            options: {
                setCellProps: () => ({
                    align: "center"
                }),
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <Chip label={value}
                            style={{
                                color: "white",
                                background: value === "Approved" ? "green" : "red"
                            }}
                        />
                    );
                }
            }
        });
    };

    const columns = [
        {
            name: 'createdDate',
            label: 'Transaction Time',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: 'coins',
            label: 'Transfered Coins',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({
                    align: "right"
                }),
                setCellProps: () => ({
                    align: "right"
                })
            }
        },
        hashRenderer(),
        statusRenderer(),
        actionRenderer()
    ];

    const data = withdraws.map((item) => {
        return {
            id: item.id,
            createdDate: moment.unix(item.createdAt._seconds).format("lll"),
            transactionCharge: item.transactionCharge + ' ' + (item.tokenSymbol ? item.tokenSymbol : item.coinType),
            coins: item.coins + ' ' + (item.tokenSymbol ? item.tokenSymbol : item.coinType),
            status: item.status,
            fromAddress: item.fromAddress,
            hash: item.transactionHash,
        };
    });


    return (
        <div>
            {
                datum.id &&
                <Dialog maxWidth="md" fullWidth open={!IsNullOrEmpty(selectedItem) && datum.id}>
                    <DialogTitle>
                        Withdraw Information
                        <Close className="float-right mt-2" onClick={() => {
                            setSelectedItem("");
                        }} />
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <WithdrawDetail withdraw={datum} />
                    </DialogContent>
                </Dialog>
            }
            {
                isLoading && <Loader />
            }
            <TitleBar heading="Profile Information" secondary={"Profile Information"}
                icon={<AccountCircle />} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Container maxWidth="sm">
                        <Card elevation={0}>
                            <CardHeader title={"Personal Information"} />
                            <Divider />
                            <CardContent>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <AccountBoxSharp />
                                        </ListItemAvatar>
                                        <ListItemText primary={currentUser.fullName} secondary="Name" />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Fingerprint />
                                        </ListItemAvatar>
                                        <ListItemText primary={currentUser.memberId} secondary="Member #" />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Email />
                                        </ListItemAvatar>
                                        <ListItemText primary={currentUser.email} secondary="Email" />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemAvatar>
                                            <PhoneAndroid />
                                        </ListItemAvatar>
                                        <ListItemText primary={currentUser.country + " " + currentUser.mobileNumber} secondary="Mobile Number" />
                                    </ListItem>
                                    <Divider />
                                </List>
                            </CardContent>
                        </Card>
                    </Container>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={2} className="my-2">
                        {
                            data.length === 0 && !isLoading ?
                                <Grid xs={12} sm={12}>
                                    {
                                        data.length === 0 &&
                                        <Box display={"flex"} height="60vh" flexDirection={"column"} justifyContent={"center"} alignItems={"center"} className="my-2">
                                            <img src={process.env.PUBLIC_URL + "/images/noData.svg"} width={"250"} />
                                        </Box>
                                    }
                                </Grid> :

                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <MUIDataTable
                                        title={'Withdraw List'}
                                        data={data}
                                        columns={columns}
                                        options={{
                                            filterType: 'checkbox',
                                            selectableRows: 'none',
                                            rowsPerPageOptions: [10],
                                        }}
                                    />
                                </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </div >
    );
}

