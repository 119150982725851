import { Avatar, Box, Button, Card, CardContent, ListItemSecondaryAction, Container, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Select, TextField } from "@material-ui/core";
import { CallMadeRounded, CallReceivedRounded, Fingerprint, Beenhere, DescriptionOutlined, AssignmentInd, AccountBalance, Collections, ExpandMore, FileCopy, Language, SwapHoriz, SyncAlt } from "@material-ui/icons";
import React, { useState } from "react";
import { QRCode } from 'react-qrcode';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import toastr from "toastr";
import _ from "underscore";
import { IsNullOrEmpty, calculateData, getBlockChainUrl, getLogo } from "../../../helpers/utility";
import { getCoinPrices, getTransactionLoading } from "../../../store/transaction/transaction.selector";
import { getCurrentUser } from "../../../store/user/user.selector";
import { getWhitelists } from "../../../store/whitelist/whitelist.selector";
import Loader from "../../base/loader";
import VerifyPin from "../../verify/verifyPin";
import { transferCoinRequest } from "../../../store/withdraw/withdraw.action";
import { getTokenBNBBalRequest } from "../../../store/user/user.actions";
export const pageSize = 9;

function WalletCard(props) {
    const { coinBalance, currentBalance, selectedItem } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [mode, setMode] = useState("");
    const [toAddress, setAddress] = useState(null);
    const [toAmount, setToAmount] = useState(null);
    const [open, setOpen] = useState(false);
    const [sourceCoin, setSourceCoin] = useState("USDT");
    const [tokenAddress, setTokenAddress] = useState("");
    const [selectedToken, setSelectedToken] = useState("");
    const [qty, setQty] = useState(1);
    const [amount, setAmount] = useState(null);
    const [currentTokenBalance, setCurrentTokenBalance] = useState(0)

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (it) => {
        setAnchorEl(null);
        setSourceCoin(it);
    };

    const { user, prices, whitelists, isLoading } = useSelector((state) => {
        return {
            user: getCurrentUser(state),
            prices: getCoinPrices(state),
            whitelists: getWhitelists(state),
            isLoading: state.WithdrawReducer.isLoading
        }
    });

    let isDisabled = IsNullOrEmpty(toAddress) ||
        IsNullOrEmpty(toAmount) ||
        isNaN(parseFloat(toAmount)) ||
        parseFloat(toAmount) > parseFloat(currentBalance);

    let isBuyDisabled = IsNullOrEmpty(qty) || IsNullOrEmpty(toAddress) || qty <= 0 || parseFloat(coinBalance[selectedItem] || 0) < parseFloat(qty);


    function handleSend() {
        if (!isDisabled) {
            if (parseFloat(toAmount) < parseFloat(currentBalance)) {
                setOpen(true);
            }
            else {
                Swal.fire({
                    title: "Validation",
                    text: "Insufficient Balance to transfer",
                    icon: "error"
                });
            }
        }
    }

    function handleOrder() {
        if (!isBuyDisabled) {
            setOpen(true);
        }
    }

    let item = _.find(user.wallets, x => x.currency === selectedItem);
    let coinPrices = calculateData(prices, coinBalance, true);
    let coinData = _.find(coinPrices, x => x.coin === selectedItem);
    let sourceCoinData = _.find(coinPrices, x => x.coin === sourceCoin);

    let amt = ((coinData && coinData.price) * qty / (sourceCoinData && sourceCoinData.price)).toFixed(8);


    if (open) {
        return <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <VerifyPin
                                isNotVerify={true}
                                id={user.id}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                onComplete={(code) => {
                                    if (mode === "Send") {
                                        dispatch(transferCoinRequest({
                                            toAddress: toAddress,
                                            coins: toAmount,
                                            coinType: selectedItem,
                                            code: code
                                        }))
                                    }

                                    setOpen(false);
                                }} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    }

    return <div>
        {
            isLoading && <Loader />
        }
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card variant="outlined">
                    <CardContent>
                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                            <img className="rounded-lg" style={{ height: 128, width: 128 }} src={getLogo(selectedItem).logo} />
                            <p className="text-primary my-1 py-1 blockquote">  {coinBalance[selectedItem] || 0}
                                &nbsp;{selectedItem}<span className="text-secondary small"> ($ {coinData && coinData.val})</span>
                            </p>
                            <p className="text-secondary my-1 py-1">

                            </p>
                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                <p className="text-center text-sm">
                                    <Avatar className={`mx-3 cursor ${mode === "Send" ? "bg-primary" : "bg-info"}`} onClick={() => {
                                        setMode("Send");
                                    }}>
                                        <CallMadeRounded />
                                    </Avatar>
                                    Send
                                </p>
                                <p className="text-center">
                                    <Avatar className={`mx-3 cursor ${mode === "Receive" ? "bg-primary" : "bg-info"}`} onClick={() => setMode("Receive")}>
                                        <CallReceivedRounded />
                                    </Avatar>
                                    Receive
                                </p>
                                <p className="text-center">
                                    <Avatar className="mx-3 cursor bg-info">
                                        <FileCopy onClick={() => {
                                            navigator.clipboard.writeText(item.address);
                                            toastr.success("Copied Address to clipboard");
                                        }} />
                                    </Avatar>
                                    Copy
                                </p>
                                {/* <p className="text-center">
                                    <Avatar className={`mx-3 cursor ${mode === "Buy" ? "bg-primary" : "bg-info"}`} onClick={() => setMode("Buy")}>
                                        <SwapHoriz />
                                    </Avatar>
                                    Swap
                                </p> */}
                                <p className="text-center">
                                    <Avatar className={`mx-3 cursor ${mode === "Assets" ? "bg-primary" : "bg-info"}`} onClick={() => setMode("Assets")}>
                                        <Collections />
                                    </Avatar>
                                    Assets
                                </p>
                                <p className="text-center">
                                    <a target="_blank" className="text-decoration-none" href={getBlockChainUrl(item.currency) + item.address}>
                                        <Avatar className="mx-3 cursor bg-info">
                                            <Language />
                                        </Avatar>
                                        Explore
                                    </a>
                                </p>
                            </Box>
                            <Box display={"flex"} width="100%" alignItems={"center"} justifyContent={"center"} >
                                {
                                    mode === "Receive" &&
                                    <Container maxWidth="sm" className="text-center">
                                        <div style={{ border: "1px solid #CECECE", display: "inline-block" }}>
                                            <QRCode value={item.address} margin={2} scale={6} />
                                        </div>
                                        <br />
                                        <TextField
                                            className="mt-4"
                                            fullWidth
                                            value={item.address}
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <FileCopy onClick={() => {
                                                        navigator.clipboard.writeText(item.address);
                                                        toastr.success("Copied Address to clipboard");
                                                    }} />
                                                </InputAdornment>
                                            }}
                                        />
                                    </Container>
                                }
                                {
                                    mode === "Send" &&
                                    <Container maxWidth="sm" className="text-center">
                                        <TextField
                                            label="Address"
                                            fullWidth
                                            value={toAddress}
                                            margin="dense"
                                            size="small"
                                            variant="outlined"
                                            onChange={(e) => {
                                                setAddress(e.target.value)
                                            }}
                                            className="my-2"
                                        />
                                        <TextField
                                            label="Coins"
                                            fullWidth
                                            value={toAmount}
                                            margin="dense"
                                            size="small"
                                            variant="outlined"
                                            onChange={(e) => {
                                                setToAmount(e.target.value)
                                            }}
                                            className="my-2"
                                        />
                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                            <Button variant="contained" color="primary" onClick={() => {
                                                setMode("");
                                                setAddress("");
                                                setToAmount("")
                                            }}>
                                                Cancel
                                            </Button>
                                            <Button variant="contained" color="secondary" disabled={isDisabled} onClick={() => {
                                                handleSend()
                                            }}>
                                                Send
                                            </Button>
                                        </Box>

                                    </Container>
                                }
                            </Box>
                            {
                                mode === "Assets" && <Grid container spacing={2} className="mt-2">
                                    {
                                        user.tokens && user.tokens.map((s) => {
                                            return <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Card variant="outlined">
                                                    <CardContent className="p-2 pb-2">
                                                        <List disablePadding>
                                                            <ListItem disableGutters className="py-0" onClick={() => {
                                                                navigator.clipboard.writeText(s.address);
                                                                toastr.success("Copied Address to clipboard");
                                                            }}>
                                                                <ListItemAvatar>
                                                                    <Avatar>
                                                                        <Fingerprint color="primary" />
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText title={s.address} primaryTypographyProps={{
                                                                    className: "overflow-text"
                                                                }} secondary='Contract Address' primary={s.address} />
                                                            </ListItem>
                                                            <ListItem disableGutters className="py-0">
                                                                <ListItemAvatar>
                                                                    <Avatar>
                                                                        <DescriptionOutlined color="primary" />
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText primary={s.balance + " " + s.symbol} secondary={s.name} />
                                                                <ListItemSecondaryAction>
                                                                    <Button onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        let isExist = _.find(user.wallets, s => s.currency === selectedItem);
                                                                        dispatch(getTokenBNBBalRequest({
                                                                            address: isExist.address,
                                                                            contractAddress: s.address
                                                                        }))
                                                                    }} variant="outlined" size="small">
                                                                        Get Balance
                                                                    </Button>
                                                                    <Button disabled={s.balance === "***" || s.balance == 0} onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setCurrentTokenBalance(s.balance);
                                                                        setTokenAddress(s.address);
                                                                        setSelectedToken(s.address);
                                                                    }} variant="outlined" className="ml-2" size="small">
                                                                        Withdraw
                                                                    </Button>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        </List>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            }
                            {
                                (mode === "Buy" || mode === "Sell") &&
                                <div className="w-100">
                                    <Container maxWidth="sm" fullWidth className="text-center">
                                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                            <List disablePadding>
                                                <ListItem disableGutters>
                                                    <ListItemAvatar>
                                                        <Avatar src={getLogo(selectedItem).logo} />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={selectedItem} secondary={coinData && coinData.price + " USD"} />
                                                </ListItem>
                                                <ListItem disableGutters>
                                                    <ListItemAvatar>
                                                        <Avatar src={getLogo(selectedItem).logo} />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={selectedItem} secondary={qty + " " + selectedItem} />
                                                </ListItem>
                                            </List>
                                            <SyncAlt />
                                            <List disablePadding>
                                                <ListItem disableGutters>
                                                    <ListItemAvatar>
                                                        <Avatar src={getLogo(sourceCoin).logo} />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={sourceCoin} secondary={parseFloat((sourceCoinData && sourceCoinData.price) || 0).toFixed(8) + " USD"} />
                                                </ListItem>
                                                <ListItem disableGutters>
                                                    <ListItemAvatar>
                                                        <Avatar src={getLogo(sourceCoin).logo} />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={sourceCoin} secondary={amt + " " + sourceCoin} />
                                                </ListItem>
                                            </List>
                                        </Box>
                                    </Container>
                                </div>
                            }
                            {
                                (mode === "Buy" || mode === "Sell") &&
                                <Container maxWidth="xs" fullWidth className="text-center">
                                    <TextField
                                        fullWidth
                                        value={qty}
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => {
                                            setQty(e.target.value)
                                        }}
                                        type="number"
                                        inputProps={{
                                            style: {
                                                textAlign: "right"
                                            },
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                Amount
                                            </InputAdornment>,
                                            endAdornment: <InputAdornment position="end">
                                                <Button style={{ minWidth: 100 }} onClick={handleClick}>
                                                    {selectedItem}&nbsp;<ExpandMore className="invisible" />
                                                </Button>

                                            </InputAdornment>
                                        }}
                                        className="my-2"
                                    />
                                    <TextField
                                        fullWidth
                                        value={amt}
                                        disabled
                                        size="small"
                                        variant="outlined"
                                        onChange={(e) => {
                                            setAmount(e.target.value)
                                        }}
                                        type="number"
                                        inputProps={{
                                            style: {
                                                textAlign: "right"
                                            },
                                            "aria-readonly": "true"
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                Price
                                            </InputAdornment>,
                                            endAdornment: <InputAdornment position="end">
                                                <Button style={{ minWidth: 100 }} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                                    {sourceCoin}&nbsp;<ExpandMore />
                                                </Button>
                                                <Menu
                                                    id="simple-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={() => handleClose("USDT")}
                                                >
                                                    <MenuItem onClick={() => handleClose("BTC")}>BTC</MenuItem>
                                                    <MenuItem onClick={() => handleClose("BNB")}>BNB</MenuItem>
                                                    <MenuItem onClick={() => handleClose("USDT")}>USDT</MenuItem>
                                                    <MenuItem onClick={() => handleClose("XRP")}>XRP</MenuItem>
                                                    <MenuItem onClick={() => handleClose("LTC")}>LTC</MenuItem>
                                                    <MenuItem onClick={() => handleClose("ETH")}>ETH</MenuItem>
                                                </Menu>
                                            </InputAdornment>
                                        }}
                                        className="my-2"
                                    />
                                    <FormControl margin="dense" variant='outlined' fullWidth>
                                        <InputLabel id="demo-simple-select-label">Address</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={toAddress}
                                            label="Address"
                                            className='my-2'
                                            onChange={(e) => setAddress(e.target.value)}
                                            displayEmpty
                                        >
                                            {
                                                whitelists.filter((t) => t.coinType.toLowerCase() === sourceCoin.toLowerCase()).map((s) => {
                                                    return <MenuItem value={s.address}>{s.address}</MenuItem>
                                                })
                                            }
                                        </Select>
                                        <FormHelperText>
                                            <Link className="float-right" to={"/whitelist"} >Add New Address</Link>
                                        </FormHelperText>

                                    </FormControl>
                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Button variant="contained" color="primary" onClick={() => {
                                            setMode("");
                                        }}>
                                            Cancel
                                        </Button>
                                        <Button variant="contained" color="secondary" disabled={isBuyDisabled} onClick={() => {
                                            handleOrder()
                                        }}>
                                            {mode} {selectedItem}
                                        </Button>
                                    </Box>
                                </Container>
                            }
                        </Box>

                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </div>
}

export default WalletCard;
