import { Container, FormControl, Grid, MenuItem, Select } from "@material-ui/core";
import { AccountBalanceWallet } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMemberRequest } from "../../store/member/member.action";
import { getMembers } from "../../store/member/member.selector";
import TitleBar from "../base/TitleBar";
import WhitelistList from "./Whitelist.list";
import { fetchWhitelistRequest } from "../../store/whitelist/whitelist.action";

function Whitelist() {
    const dispatch = useDispatch();
    const [member, setMember] = useState("");

    const { members } = useSelector((state) => {
        return {
            members: getMembers(state),
        }
    });

    useEffect(() => {
        if (members.length === 0) {
            dispatch(fetchMemberRequest());
        }
    }, [members])

    return (
        <div className="mt-2">
            <TitleBar heading="Whitelist" secondary={"Whitelist"}
                icon={<AccountBalanceWallet />}
            />
            <Grid container spacing={2} className="my-2">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Container maxWidth="sm">
                        <FormControl fullWidth variant="outlined">
                            <Select
                                margin="dense"
                                fullWidth
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={member}
                                displayEmpty
                                onChange={(e) => {
                                    setMember(e.target.value);
                                    dispatch(fetchWhitelistRequest(e.target.value))
                                }}
                            >
                                <MenuItem value={""}>Select Member</MenuItem>
                                {
                                    members.map((x) => <MenuItem value={x.id}>{x.fullName}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Container>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <WhitelistList />
                </Grid>
            </Grid>
        </div>
    );
}

export default Whitelist;
