import HttpHelper from "../../helpers/httpHelper";
import { getSettingUrl, updateSettingUrl } from "./configuration.uri";

class ConfigurationAPI {
    static async GetSetting() {
        let url = getSettingUrl();
        return HttpHelper.httpRequest(url, 'GET', {});
    }

    static async UpdateSetting(req) {
        let url = updateSettingUrl(req.code);
        delete req.code
        return HttpHelper.httpRequest(url, 'PATCH', {}, req);
    }
}

export default ConfigurationAPI;
