import HttpHelper from "../../helpers/httpHelper";
import { getWithdrawUrl, getTransferUrl, getMyWithdrawUrl } from "./withdraw.uri";

class WithdrawAPI {
    static async GetWithdraws(id) {
        let url = getWithdrawUrl(id);
        return HttpHelper.httpRequest(url, 'GET', {});
    }
    static async GetMyWithdraws(id) {
        let url = getMyWithdrawUrl(id);
        return HttpHelper.httpRequest(url, 'GET', {});
    }

    static async TransferCoins(req) {
        let url = getTransferUrl(req.code);
        delete req.code;
        return HttpHelper.httpRequest(url, 'POST', {}, req);
    }
}

export default WithdrawAPI;
