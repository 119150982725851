import { Box, Chip, Dialog, DialogContent, DialogTitle, Divider, Grid } from "@material-ui/core";
import { Close, Visibility } from "@material-ui/icons";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import { IsNullOrEmpty } from "../../helpers/utility";
import { updateOrderRequest } from "../../store/order/order.actions";
import { getOrders } from "../../store/order/order.selector";
import Loader from "../base/loader";
import VerifyPin from "../verify/verifyPin";
import OrderDetail from "./OrderDetail";

function OrderList(props) {
    const dispatch = useDispatch();
    const [selectedItem, setSelectedItem] = useState("");
    const [datum, setDatum] = useState({});
    const [status, setStatus] = useState("");
    const [open, setOpen] = useState(false);

    const { orders, isLoading } = useSelector((state) => {
        return {
            orders: getOrders(state),
            isLoading: state.OrderReducer.isLoading
        }
    });

    useEffect(() => {
        if (selectedItem) {
            let z = _.find(orders, x => x.id === selectedItem);
            setDatum(z);
        }
        else {
            setDatum({});
        }
    }, [selectedItem])

    const actionRenderer = () => {
        return ({
            name: 'id',
            label: 'Info',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    let user = orders.filter((item) => {
                        return item.id === value;
                    });
                    return (
                        <Visibility
                            color="secondary"
                            onClick={() => setSelectedItem(value)}
                        />
                    );
                }
            }
        });
    };

    const statusRenderer = () => {
        return ({
            name: 'status',
            label: 'Status',
            options: {
                setCellProps: () => ({
                    align: "center"
                }),
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <Chip label={value}
                            style={{
                                color: "white",
                                background: value === "Approved" ? "green" :
                                    value === "Pending" ? "goldenrod" : "red"
                            }}
                        />
                    );
                }
            }
        });
    };

    const columns = [
        {
            name: 'createdDate',
            label: 'Transaction Time',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: 'fromCoins',
            label: 'From Coins',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({
                    align: "right"
                }),
                setCellProps: () => ({
                    align: "right"
                })
            }
        },
        {
            name: 'toCoins',
            label: 'To Coins',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({
                    align: "right"
                }),
                setCellProps: () => ({
                    align: "right"
                })
            }
        },
        {
            name: 'transactionCharge',
            label: 'Transaction Charge',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({
                    align: "right"
                }),
                setCellProps: () => ({
                    align: "right"
                })
            }
        },
        {
            name: 'mode',
            label: 'Mode',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({
                    align: "right"
                }),
                setCellProps: () => ({
                    align: "right"
                })
            }
        },
        statusRenderer(),
        actionRenderer()
    ];

    const data = orders.map((item) => {
        return {
            id: item.id,
            createdDate: moment.unix(item.createdAt._seconds).format("lll"),
            transactionCharge: item.transactionCharge + ' ' + (item.tokenSymbol ? item.tokenSymbol : item.fromCoinType),
            fromCoins: item.fromCoins + ' ' + (item.tokenSymbol ? item.tokenSymbol : item.fromCoinType),
            toCoins: item.toCoins + ' ' + (item.tokenSymbol ? item.tokenSymbol : item.toCoinType),
            status: item.status === "Approved" ? item.adminStatus : item.status,
            fromAddress: item.fromAddress,
            mode: item.mode,
            hash: item.adminTransactionHash || item.fromTransactionHash,
        };
    });

    return (
        <div>
            <Dialog open={open}>
                <DialogContent>
                    <VerifyPin
                        onClose={() => {
                            setOpen(false);
                        }}
                        isNotVerify={true}
                        onComplete={(code) => {
                            dispatch(updateOrderRequest({
                                code: code,
                                id: selectedItem,
                                status: status
                            }))

                            setOpen(false);
                            setSelectedItem("");
                            setDatum({});
                        }} />
                </DialogContent>
            </Dialog>
            {
                datum.id &&
                <Dialog maxWidth="md" fullWidth open={!IsNullOrEmpty(selectedItem) && datum.id && !open}>
                    <DialogTitle>
                        Order Information
                        <Close className="float-right mt-2" onClick={() => {
                            setSelectedItem("");
                        }} />
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <OrderDetail order={datum} onUpdate={(status) => {
                            setStatus(status);
                            setOpen(true)
                        }} />
                    </DialogContent>
                </Dialog>
            }
            {
                isLoading && <Loader />
            }
            <Grid container spacing={2} className="my-2">
                {
                    data.length === 0 && !isLoading ?
                        <Grid xs={12} sm={12}>
                            {
                                data.length === 0 &&
                                <Box display={"flex"} height="60vh" flexDirection={"column"} justifyContent={"center"} alignItems={"center"} className="my-2">
                                    <img src={process.env.PUBLIC_URL + "/images/noData.svg"} width={"250"} />
                                </Box>
                            }
                        </Grid> :

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <MUIDataTable
                                title={'Order List'}
                                data={data}
                                columns={columns}
                                options={{
                                    filterType: 'checkbox',
                                    selectableRows: 'none',
                                    rowsPerPageOptions: [10],
                                }}
                            />
                        </Grid>
                }
            </Grid>
        </div>
    );
}

export default OrderList;
