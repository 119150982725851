import { Button, Grid } from "@material-ui/core";
import { AccountBalanceWallet } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMemberRequest } from "../../store/member/member.action";
import { getMembers } from "../../store/member/member.selector";
import TitleBar from "../base/TitleBar";
import MemberList from "./Member.list";

function Member() {
    const dispatch = useDispatch();
    const [mode, setMode] = useState("list");
    const [selectedItem, setSelectedIdItem] = useState("");
    const [isEdit, setEdit] = useState(false);

    const { members, isLoading } = useSelector((state) => {
        return {
            members: getMembers(state),
            isLoading: state.MemberReducer.isLoading
        }
    });

    useEffect(() => {
        dispatch(fetchMemberRequest())
    }, [])

    useEffect(() => {
        setMode("list");
    }, [members])

    return (
        <div className="mt-2">
            <TitleBar heading="Member" secondary={"Member"}
                icon={<AccountBalanceWallet />}
            />
            <Grid container spacing={2} className="my-2">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MemberList onEdit={(item) => {
                        setEdit(true);
                        setSelectedIdItem(item);
                        setMode("Edit")
                    }} />
                </Grid>
            </Grid>
        </div>
    );
}

export default Member;
