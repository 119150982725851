import HttpHelper from "../../helpers/httpHelper";
import { getOrderUrl, updateOrderUrl } from "./order.uri";

class OrderAPI {
    static async GetOrders(req) {
        let url = getOrderUrl(req);
        return HttpHelper.httpRequest(url, 'GET', {});
    }

    static async UpdateOrder(req) {
        let url = updateOrderUrl(req);
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }
}

export default OrderAPI;
