import { Avatar, Box, Dialog, DialogContent, DialogTitle, Divider, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { AccountBalance, AccountBalanceWallet, CalendarToday, CalendarViewDay, Close, Description, Fingerprint, ListAlt, OpenInNew, Visibility } from "@material-ui/icons";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "underscore";
import { IsNullOrEmpty, getBlockChainUrl } from "../../helpers/utility";
import { getWhitelists } from "../../store/whitelist/whitelist.selector";
import Loader from "../base/loader";

function WhitelistList(props) {
    const [selectedItem, setSelectedItem] = useState("");
    const [datum, setDatum] = useState({});

    const { whitelists, isLoading } = useSelector((state) => {
        return {
            whitelists: getWhitelists(state),
            isLoading: state.WhitelistReducer.isLoading
        }
    });

    useEffect(() => {
        if (selectedItem) {
            let z = _.find(whitelists, x => x.id === selectedItem);
            setDatum(z);
        }
        else {
            setDatum({});
        }
    }, [selectedItem])


    let filteredList = whitelists;
    if (!filteredList) {
        filteredList = [];
    }

    filteredList = _.sortBy(filteredList, "createdAt").reverse();

    const actionRenderer = () => {
        return ({
            name: 'id',
            label: 'Action',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    return (
                        <>
                            <Visibility
                                color="secondary"
                                onClick={() => setSelectedItem(value)}
                            />
                        </>
                    );
                }
            }
        });
    };

    const columns = [
        {
            name: 'address',
            label: 'Address',
            options: {
                filter: false,
                sort: true,
            }
        },

        {
            name: 'createdAt',
            label: 'Created On',
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: 'activatedAt',
            label: 'Activated On',
            options: {
                filter: false,
                sort: false,
            }
        },

        actionRenderer()
    ];

    const data = filteredList.map((item) => {
        return {
            minCoins: item.minCoins,
            maxCoins: item.maxCoins,
            coolingPeriod: item.coolingPeriod,
            createdAt: moment.unix(item.createdAt._seconds).format("LLL"),
            activatedAt: moment.unix(item.createdAt._seconds).add(item.coolingPeriod, "seconds").format("LLL"),
            address: item.address,
            id: item.id,
            status: item.status
        }
    })

    return (
        <div>
            {
                isLoading && <Loader />
            }
            {
                datum.id &&
                <Dialog maxWidth="md" fullWidth open={!IsNullOrEmpty(selectedItem) && datum.id}>
                    <DialogTitle>
                        Whitelist Information
                        <Close className="float-right mt-2" onClick={() => {
                            setSelectedItem("");
                        }} />
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <AccountBalance color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Min Coins' primary={`${datum.minCoins} ${datum.tokenSymbol || datum.coinType}`} />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <AccountBalanceWallet color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Max Coins' primary={`${datum.maxCoins} ${datum.tokenSymbol || datum.coinType}`} />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <ListAlt color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Status' primary={datum.status} />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <Description color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Address' primary={datum.address} primaryTypographyProps={{
                                            className: "overflow-text"
                                        }} />
                                        <ListItemSecondaryAction>
                                            <a target='_blank' href={getBlockChainUrl(datum.coinType) + datum.address}>
                                                <OpenInNew color="primary" />
                                            </a>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <Fingerprint color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Nickname' primary={datum.nickName} />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <CalendarToday color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Created On' primary={moment.unix(datum.createdAt._seconds).format("lll")} />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <CalendarViewDay color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Activated On' primary={moment.unix(datum.updatedAt._seconds).add(datum.coolingPeriod, "seconds").format("lll")} />
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }
            <Grid container spacing={2} className="my-2">
                {
                    data.length === 0 && !isLoading ?
                        <Grid xs={12} sm={12}>
                            {
                                data.length === 0 &&
                                <Box display={"flex"} height="60vh" flexDirection={"column"} justifyContent={"center"} alignItems={"center"} className="my-2">
                                    <img src={process.env.PUBLIC_URL + "/images/noData.svg"} width={"250"} />
                                </Box>
                            }
                        </Grid> :

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <MUIDataTable
                                title={'Whitelist List'}
                                data={data}
                                columns={columns}
                                options={{
                                    filterType: 'checkbox',
                                    selectableRows: 'none',
                                    rowsPerPageOptions: [10],
                                }}
                            />
                        </Grid>
                }
            </Grid>
        </div>
    );
}

export default WhitelistList;
