export const ConfigurationActionTypes = {
  FETCH_SETTING_REQUEST: '@@configuration/FETCH_SETTING_REQUEST',
  FETCH_SETTING_SUCCESS: '@@configuration/FETCH_SETTING_SUCCESS',
  FETCH_SETTING_FAILURE: '@@configuration/FETCH_SETTING_FAILURE',

  UPDATE_SETTING_REQUEST: '@@configuration/UPDATE_SETTING_REQUEST',
  UPDATE_SETTING_SUCCESS: '@@configuration/UPDATE_SETTING_SUCCESS',
  UPDATE_SETTING_FAILURE: '@@configuration/UPDATE_SETTING_FAILURE',
}

export const configurationInitialState = {
  setting: {
    id: "",
    walletConfig: []
  },
  isLoading: false
}