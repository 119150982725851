export const API_URL = process.env.REACT_APP_API_URL;

export function get2FASetup() {
    return API_URL + '/admin/2fa/setup';
}

export function verify2FAUrl() {
    return API_URL + '/admin/2fa/verify';
}

export function updateTokensUrl() {
    return API_URL + '/admin/tokens/update';
}

export function updateKYCUrl() {
    return API_URL + '/admin/kyc';
}

export function getBNBBalanceUrl(req) {
    return API_URL + '/token/bnb/balance/' + req.contractAddress + "?address=" + req.address;
}


