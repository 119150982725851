import HttpHelper from '../../helpers/httpHelper';
import { get2FASetup, getBNBBalanceUrl, updateKYCUrl, updateTokensUrl, verify2FAUrl } from "./user.uri";

require('dotenv').config();
const headerInfo = {};

class UserAPI {
    static async UpdateTokens(tokenInfo) {
        let url = updateTokensUrl();
        return HttpHelper.httpRequest(url, 'PATCH', {}, tokenInfo);
    }

    static async UpdateKycInformation(req) {
        let url = updateKYCUrl();
        return await HttpHelper.httpRequest(url, 'PUT', {}, req);
    }

    static async GetCoinBalance() {
        let url = process.env.REACT_APP_API_URL + '/admin/wallets';
        return await HttpHelper.httpRequest(url, 'GET', {});
    }

    static async RegisterUser(req) {
        let url = process.env.REACT_APP_API_URL + '/admin';
        return HttpHelper.httpRequest(url, 'POST', headerInfo, req);
    }

    static async GetCurrentUser() {
        let url = process.env.REACT_APP_API_URL + '/admin';
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async Get2FASetUpCode(req) {
        let url = get2FASetup();
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }

    static async Verify2FACode(code) {
        let url = verify2FAUrl();
        return HttpHelper.httpRequest(url, 'PATCH', {}, {
            "code": code
        });
    }

    static async GetBNBWalletAddressBal(req) {
        let url = getBNBBalanceUrl(req);
        return await HttpHelper.httpRequest(url, 'GET', {});
    }
}

export default UserAPI;
