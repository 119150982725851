import { Avatar, Button, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { AccountBalance, AccountBalanceWallet, CalendarToday, CalendarViewDay, FileCopy, Fingerprint, FlipToBackOutlined, OpenInBrowser, OpenInNew, TransferWithinAStation, ZoomOutMapOutlined } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import toastr from "toastr";
import { getBlockChainUrl, getTransactionUrl } from '../../helpers/utility';
import Swal from 'sweetalert2';

export default function OrderDetail(props) {
    const { order, onUpdate } = props;

    const handleStatus = (status) => {
        Swal.fire({
            title: "Update Status",
            text: "Are you sure want to update the status to " + status,
            icon: "question",
            confirmButtonText: "Yes Proceed",
            showCancelButton: true
        }).then((d) => {
            if (d.isConfirmed) {
                onUpdate(status)
            }
        })
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <Fingerprint color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='Order ID' primary={order.id} />
                        {
                            navigator && navigator.clipboard &&
                            <ListItemSecondaryAction>
                                <FileCopy color="primary" onClick={() => {
                                    navigator.clipboard.writeText(order.id);
                                    toastr.success("Copied to clipboard");
                                }} />
                            </ListItemSecondaryAction>
                        }
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <TransferWithinAStation color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='Order Mode' primary={order.mode} />
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <AccountBalance color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='From Coins' primary={`${order.tokenSymbol || order.fromCoinType} ${order.fromCoins}`} />
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <AccountBalance color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='To Coins' primary={`${order.tokenSymbol || order.toCoinType} ${order.toCoins}`} />
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <AccountBalanceWallet color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='Transaction Charge' primary={`${order.tokenSymbol || order.fromCoinType} ${order.transactionCharge}`} />
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <AccountBalanceWallet color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='Order Type' classes={{
                            primary: "text-capitalize"
                        }} primary={`${order.orderType}`} />
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <ZoomOutMapOutlined color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='Admin Status' primary={order.adminStatus} />

                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <ZoomOutMapOutlined color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='Status' primary={order.status} />
                        {
                            order.mode === "Cash" && order.status === "Initiated" &&
                            <ListItemSecondaryAction>
                                <Button size="small" onClick={() => handleStatus("Approved")} variant='contained' color="primary">
                                    Approve
                                </Button>&nbsp;
                                <Button size="small" onClick={() => handleStatus("Rejected")} color="default" variant='contained'>
                                    Reject
                                </Button>
                            </ListItemSecondaryAction>
                        }
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <CalendarToday color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='Created On' primary={moment.unix(order.createdAt._seconds).format("lll")} />
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <CalendarViewDay color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='Last Modified On' primary={moment.unix(order.updatedAt._seconds).format("lll")} />
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <OpenInBrowser color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='From Address' primary={order.fromAddress} />
                        {
                            navigator && navigator.clipboard &&
                            <ListItemSecondaryAction>
                                <a target='_blank' href={getBlockChainUrl(order.fromCoinType) + order.fromAddress}>
                                    <OpenInNew color="primary" />
                                </a>
                            </ListItemSecondaryAction>
                        }
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <OpenInBrowser color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='To Address' primary={order.receiveAddress} />
                        {
                            navigator && navigator.clipboard &&
                            <ListItemSecondaryAction>
                                <a target='_blank' href={getBlockChainUrl(order.toCoinType) + order.receiveAddress}>
                                    <OpenInNew color="primary" />
                                </a>
                            </ListItemSecondaryAction>
                        }
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <OpenInBrowser color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='Admin Address' primary={order.toAddress} />
                        {
                            navigator && navigator.clipboard &&
                            <ListItemSecondaryAction>
                                <a target='_blank' href={getBlockChainUrl(order.fromCoinType) + order.toAddress}>
                                    <OpenInNew color="primary" />
                                </a>
                            </ListItemSecondaryAction>
                        }
                    </ListItem>
                </List>
            </Grid>
            {
                order.contractAddress &&
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <List disablePadding>
                        <ListItem disableGutters>
                            <ListItemAvatar>
                                <Avatar>
                                    <FlipToBackOutlined color="primary" />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText secondary='Token Address' primary={order.contractAddress} />
                            {
                                navigator && navigator.clipboard &&
                                <ListItemSecondaryAction>
                                    <a target='_blank' href={getBlockChainUrl(order.fromCoinType) + order.contractAddress}>
                                        <OpenInNew color="primary" />
                                    </a>
                                </ListItemSecondaryAction>
                            }
                        </ListItem>
                    </List>
                </Grid>
            }
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <Fingerprint color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='Send Transaction Hash' primary={order.fromTransactionHash} />
                        {
                            navigator && navigator.clipboard &&
                            <ListItemSecondaryAction>
                                <a target='_blank' href={getTransactionUrl(order.fromCoinType) + order.fromTransactionHash}>
                                    <OpenInNew color="primary" />
                                </a>
                            </ListItemSecondaryAction>
                        }
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <List disablePadding>
                    <ListItem disableGutters>
                        <ListItemAvatar>
                            <Avatar>
                                <Fingerprint color="primary" />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary='Receive Transaction Charge Hash' primary={order.adminTransactionHash} />
                        {
                            navigator && navigator.clipboard &&
                            <ListItemSecondaryAction>
                                <a target='_blank' href={getTransactionUrl(order.toCoinType) + order.adminTransactionHash}>
                                    <OpenInNew color="primary" />
                                </a>
                            </ListItemSecondaryAction>
                        }
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
}
