import HttpHelper from "../../helpers/httpHelper";
import { activateUserUrl, approveKYCUrl, getMemberUrl, rejectKYCUrl, suspendUserUrl } from "./member.uri";

class MemberAPI {
    static async GetMembers() {
        let url = getMemberUrl();
        return HttpHelper.httpRequest(url, 'GET', {});
    }
    static async ActivateUser(req) {
        let url = activateUserUrl(req);
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }

    static async SuspendUser(req) {
        let url = suspendUserUrl(req);
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }

    static async ApproveKYC(req) {
        let url = approveKYCUrl(req);
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }

    static async RejectKYC(req) {
        let url = rejectKYCUrl(req);
        return HttpHelper.httpRequest(url, 'PATCH', {});
    }
}

export default MemberAPI;
