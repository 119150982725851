export function getWithdrawLoading(state) {
    return state.WithdrawReducer.isLoading;
}

export function getWithdraws(state) {
    return state.WithdrawReducer.withdraws;
}

export function getMyWithdraws(state) {
    return state.WithdrawReducer.myWithdraws;
}