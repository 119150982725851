import { OrderActionTypes, orderInitialState } from './order.model';

const reducer = (state = orderInitialState, action) => {
    switch (action.type) {
        case OrderActionTypes.FETCH_ORDER_REQUEST:
        case OrderActionTypes.UPDATE_ORDER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case OrderActionTypes.FETCH_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                orders: action.payload
            };

        case OrderActionTypes.UPDATE_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                orders: state.orders.map((t) => {
                    if (t.id === action.payload.id) {
                        t = action.payload;
                    }
                    return t;
                })
            };

        case OrderActionTypes.FETCH_ORDER_FAILURE:
        case OrderActionTypes.UPDATE_ORDER_FAILURE:
            return {
                ...state,
                isLoading: false
            };

        default:
            return state;
    }
}

export { reducer as OrderReducer };