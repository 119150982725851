export const API_URL = process.env.REACT_APP_API_URL;

export function getOrderUrl(req) {
    return API_URL + '/order?orderType=' + req.type + "&tenantId=" + req.id;
}

export function updateOrderUrl(req) {
    return API_URL + `/order/${req.id}?code=${req.code}&status=${req.status}`;
}



