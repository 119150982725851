import { ConfigurationActionTypes, configurationInitialState } from './configuration.model';

const reducer = (state = configurationInitialState, action) => {
    switch (action.type) {
        case ConfigurationActionTypes.FETCH_SETTING_REQUEST:
        case ConfigurationActionTypes.UPDATE_SETTING_REQUEST:
            return {
                ...state,
                isLoading: true,
            };

        case ConfigurationActionTypes.FETCH_SETTING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                setting: action.payload
            };

        case ConfigurationActionTypes.UPDATE_SETTING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                setting: action.payload
            };

        case ConfigurationActionTypes.FETCH_SETTING_FAILURE:
        case ConfigurationActionTypes.UPDATE_SETTING_FAILURE:
            return {
                ...state,
                isLoading: false,
            };

        default:
            return state;
    }
}

export { reducer as ConfigurationReducer };