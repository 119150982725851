import { action } from "typesafe-actions";
import { MemberActionTypes } from "./member.model";

export const fetchMemberRequest = (req) => action(MemberActionTypes.FETCH_MEMBER_REQUEST, req);
export const fetchMemberSuccess = (res) => action(MemberActionTypes.FETCH_MEMBER_SUCCESS, res);
export const fetchMemberFailure = (err) => action(MemberActionTypes.FETCH_MEMBER_FAILURE, err);

export const activateMemberRequest = (req) => action(MemberActionTypes.ACTIVATE_MEMBER_REQUEST, req);
export const activateMemberSuccess = (res) => action(MemberActionTypes.ACTIVATE_MEMBER_SUCCESS, res);
export const activateMemberFailure = (err) => action(MemberActionTypes.ACTIVATE_MEMBER_FAILURE, err);

export const suspendMemberRequest = (req) => action(MemberActionTypes.SUSPEND_MEMBER_REQUEST, req);
export const suspendMemberSuccess = (res) => action(MemberActionTypes.SUSPEND_MEMBER_SUCCESS, res);
export const suspendMemberFailure = (err) => action(MemberActionTypes.SUSPEND_MEMBER_FAILURE, err);

export const approveKYCRequest = (req) => action(MemberActionTypes.APPROVE_KYC_REQUEST, req);
export const approveKYCSuccess = (res) => action(MemberActionTypes.APPROVE_KYC_SUCCESS, res);
export const approveKYCFailure = (err) => action(MemberActionTypes.APPROVE_KYC_FAILURE, err);

export const rejectKYCRequest = (req) => action(MemberActionTypes.REJECT_KYC_REQUEST, req);
export const rejectKYCSuccess = (res) => action(MemberActionTypes.REJECT_KYC_SUCCESS, res);
export const rejectKYCFailure = (err) => action(MemberActionTypes.REJECT_KYC_FAILURE, err);
