export const MemberActionTypes = {
    FETCH_MEMBER_REQUEST: '@@member/FETCH_MEMBER_REQUEST',
    FETCH_MEMBER_SUCCESS: '@@member/FETCH_MEMBER_SUCCESS',
    FETCH_MEMBER_FAILURE: '@@member/FETCH_MEMBER_FAILURE',

    SUSPEND_MEMBER_REQUEST: '@@member/SUSPEND_MEMBER_REQUEST',
    SUSPEND_MEMBER_SUCCESS: '@@member/SUSPEND_MEMBER_SUCCESS',
    SUSPEND_MEMBER_FAILURE: '@@member/SUSPEND_MEMBER_FAILURE',

    ACTIVATE_MEMBER_REQUEST: '@@member/ACTIVATE_MEMBER_REQUEST',
    ACTIVATE_MEMBER_SUCCESS: '@@member/ACTIVATE_MEMBER_SUCCESS',
    ACTIVATE_MEMBER_FAILURE: '@@member/ACTIVATE_MEMBER_FAILURE',

    APPROVE_KYC_REQUEST: '@@member/APPROVE_KYC_REQUEST',
    APPROVE_KYC_SUCCESS: '@@member/APPROVE_KYC_SUCCESS',
    APPROVE_KYC_FAILURE: '@@member/APPROVE_KYC_FAILURE',

    REJECT_KYC_REQUEST: '@@member/REJECT_KYC_REQUEST',
    REJECT_KYC_SUCCESS: '@@member/REJECT_KYC_SUCCESS',
    REJECT_KYC_FAILURE: '@@member/REJECT_KYC_FAILURE',
}

export const memberInitialState = {
    members: [],
    isLoading: false
}