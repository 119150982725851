export const API_URL = process.env.REACT_APP_API_URL;

export function getWithdrawUrl(id) {
    return API_URL + '/withdraw?tenantId=' + id;
}

export function getMyWithdrawUrl(id) {
    return API_URL + '/mywithdraw';
}

export function getTransferUrl(code) {
    return API_URL + `/coin/withdraw?code=${code}`;
}