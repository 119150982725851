export const OrderActionTypes = {
  FETCH_ORDER_REQUEST: '@@order/FETCH_ORDER_REQUEST',
  FETCH_ORDER_SUCCESS: '@@order/FETCH_ORDER_SUCCESS',
  FETCH_ORDER_FAILURE: '@@order/FETCH_ORDER_FAILURE',

  UPDATE_ORDER_REQUEST: '@@order/UPDATE_ORDER_REQUEST',
  UPDATE_ORDER_SUCCESS: '@@order/UPDATE_ORDER_SUCCESS',
  UPDATE_ORDER_FAILURE: '@@order/UPDATE_ORDER_FAILURE',
}

export const orderInitialState = {
  orders: [],
  prices: [],
  isLoading: false
}