export const WithdrawActionTypes = {
    FETCH_WITHDRAW_REQUEST: '@@withdraw/FETCH_WITHDRAW_REQUEST',
    FETCH_WITHDRAW_SUCCESS: '@@withdraw/FETCH_WITHDRAW_SUCCESS',
    FETCH_WITHDRAW_FAILURE: '@@withdraw/FETCH_WITHDRAW_FAILURE',

    FETCH_MY_WITHDRAW_REQUEST: '@@withdraw/FETCH_MY_WITHDRAW_REQUEST',
    FETCH_MY_WITHDRAW_SUCCESS: '@@withdraw/FETCH_MY_WITHDRAW_SUCCESS',
    FETCH_MY_WITHDRAW_FAILURE: '@@withdraw/FETCH_MY_WITHDRAW_FAILURE',

    TRANSFER_COINS_REQUEST: '@@withdraw/TRANSFER_COINS_REQUEST',
    TRANSFER_COINS_SUCCESS: '@@withdraw/TRANSFER_COINS_SUCCESS',
    TRANSFER_COINS_FAILURE: '@@withdraw/TRANSFER_COINS_FAILURE',
}

export const withdrawInitialState = {
    withdraws: [],
    myWithdraws: [],
    isLoading: false
}