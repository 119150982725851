import { Avatar, Box, Button, Card, CardHeader, Chip, Dialog, DialogContent, DialogTitle, Divider, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { CalendarToday, Close, Email, FileCopy, Fingerprint, ListAlt, Person, Phone, Visibility } from "@material-ui/icons";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import _ from "underscore";
import { IsNullOrEmpty, getLogo } from "../../helpers/utility";
import { activateMemberRequest, approveKYCRequest, rejectKYCRequest, suspendMemberRequest } from "../../store/member/member.action";
import { getMembers } from "../../store/member/member.selector";
import Loader from "../base/loader";
import VerifyPin from "../verify/verifyPin";

function MemberList(props) {
    const dispatch = useDispatch();
    const [selectedItem, setSelectedItem] = useState("");
    const [datum, setDatum] = useState({});
    const [open, setOpen] = useState(false);
    const [mode, setMode] = useState("");

    const { members, isLoading } = useSelector((state) => {
        return {
            members: getMembers(state),
            isLoading: state.MemberReducer.isLoading
        }
    });

    useEffect(() => {
        if (selectedItem) {
            let z = _.find(members, x => x.id === selectedItem);
            setDatum(z);
        }
        else {
            setDatum({});
        }
    }, [selectedItem])


    let filteredList = members;
    if (!filteredList) {
        filteredList = [];
    }

    filteredList = _.sortBy(filteredList, "createdAt").reverse();

    const actionRenderer = () => {
        return ({
            name: 'id',
            label: 'Action',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    return (
                        <>
                            <Visibility
                                color="secondary"
                                onClick={() => setSelectedItem(value)}
                            />
                        </>
                    );
                }
            }
        });
    };

    const columns = [
        {
            name: 'name',
            label: 'Name',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: 'isEmailVerified',
            label: 'Email Verified',
            options: {
                setCellProps: () => ({
                    align: "center"
                }),
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <Chip label={value ? "Yes" : "No"}
                            style={{
                                color: "white",
                                background: value ? "green" : "red"
                            }}
                        />
                    );
                }
            }
        },
        {
            name: 'isMobileVerified',
            label: 'Mobile Verified',
            options: {
                setCellProps: () => ({
                    align: "center"
                }),
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <Chip label={value ? "Yes" : "No"}
                            style={{
                                color: "white",
                                background: value ? "green" : "red"
                            }}
                        />
                    );
                }
            }
        },
        {
            name: 'is2FASetup',
            label: 'Wallet Created',
            options: {
                setCellProps: () => ({
                    align: "center"
                }),
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <Chip label={value ? "Yes" : "No"}
                            style={{
                                color: "white",
                                background: value ? "green" : "red"
                            }}
                        />
                    );
                }
            }
        },
        {
            name: 'isKYCVerified',
            label: 'KYC Verified',
            options: {
                setCellProps: () => ({
                    align: "center"
                }),
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <Chip label={value ? "Yes" : "No"}
                            style={{
                                color: "white",
                                background: value ? "green" : "red"
                            }}
                        />
                    );
                }
            }
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                setCellProps: () => ({
                    align: "center"
                }),
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <Chip label={value}
                            style={{
                                color: "white",
                                background: value === "Active" ? "green" : "red"
                            }}
                        />
                    );
                }
            }
        },
        {
            name: 'createdAt',
            label: 'Created On',
            options: {
                filter: false,
                sort: false
            }
        },
        actionRenderer()
    ];

    const data = filteredList.map((item) => {
        return {
            name: item.fullName,
            email: item.email,
            id: item.id,
            isKYCVerified: item.isKYCVerified,
            status: item.status,
            isMobileVerified: item.isMobileVerified,
            isEmailVerified: item.isEmailVerified,
            is2FASetup: item.wallets.length > 0,
            createdAt: moment.unix(item.createdAt._seconds).format("LLL"),
        }
    })

    return (
        <div>
            <Dialog open={open}>
                <DialogContent>
                    <VerifyPin
                        onClose={() => {
                            setOpen(false);
                        }}
                        isNotVerify={true}
                        onComplete={(code) => {
                            if (mode === "Activate") {
                                dispatch(activateMemberRequest(
                                    {
                                        code: code,
                                        id: selectedItem
                                    }
                                ))
                            }
                            else if (mode === "Suspend") {
                                dispatch(suspendMemberRequest({
                                    code: code,
                                    id: selectedItem,
                                }))
                            }
                            else if (mode === "ApproveKYC") {
                                dispatch(approveKYCRequest({
                                    code: code,
                                    id: selectedItem,
                                }))
                            }
                            else if (mode === "RejectKYC") {
                                dispatch(rejectKYCRequest({
                                    code: code,
                                    id: selectedItem,
                                }))
                            }
                            setOpen(false);
                            setSelectedItem("");
                            setDatum({});
                            setMode("");
                        }} />
                </DialogContent>
            </Dialog>
            {
                isLoading && <Loader />
            }
            {
                datum.id &&
                <Dialog maxWidth="md" fullWidth open={!IsNullOrEmpty(selectedItem) && datum.id && !open}>
                    <DialogTitle>
                        Member Information
                        <Close className="float-right mt-2" onClick={() => {
                            setSelectedItem("");
                        }} />
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <Person color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Name' primary={datum.fullName} />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <Fingerprint color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Member ID' primary={datum.memberId} />
                                        {
                                            datum.status === "Active" ?
                                                <ListItemSecondaryAction>
                                                    <Button color="default" onClick={() => {
                                                        setOpen(true);
                                                        setMode("Suspend");
                                                    }} variant="outlined" size='small' >Suspend Now</Button>
                                                </ListItemSecondaryAction> :
                                                datum.status === "Suspended" ?
                                                    <ListItemSecondaryAction>
                                                        <Button onClick={() => {
                                                            setOpen(true);
                                                            setMode("Activate");
                                                        }} color="primary" variant="contained" size='small' >Re Activate</Button>
                                                    </ListItemSecondaryAction> :
                                                    <></>
                                        }
                                    </ListItem>
                                </List>

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <Email color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Email' primary={datum.email} />
                                        {
                                            datum.isEmailVerified ?
                                                <ListItemSecondaryAction>
                                                    <Chip label="Verified" color="secondary" size='small' />
                                                </ListItemSecondaryAction> :
                                                <ListItemSecondaryAction>
                                                    <Chip label="Yet to verify" color="default" size='small' />
                                                </ListItemSecondaryAction>
                                        }
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <Phone color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Mobile' primary={datum.country + " " + datum.mobileNumber} />
                                        {
                                            datum.isMobileVerified ?
                                                <ListItemSecondaryAction>
                                                    <Chip label="Verified" color="secondary" size='small' />
                                                </ListItemSecondaryAction> :
                                                <ListItemSecondaryAction>
                                                    <Chip label="Yet to verify" color="default" size='small' />
                                                </ListItemSecondaryAction>
                                        }
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <ListAlt color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Status' primary={datum.status} />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <CalendarToday color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='Joined On' primary={moment.unix(datum.createdAt._seconds).format("lll")} />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <Fingerprint color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='KYC ID' primary={datum.kycId} />
                                        {
                                            datum.isKYCVerified ?
                                                <ListItemSecondaryAction>
                                                    <Chip label="Verified" color="secondary" size='small' />
                                                </ListItemSecondaryAction> :
                                                <ListItemSecondaryAction>
                                                    <Chip label="Yet to verify" color="default" size='small' />
                                                </ListItemSecondaryAction>
                                        }
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <List disablePadding>
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <Fingerprint color="primary" />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary='KYC No' primary={datum.kycNo} />
                                        {
                                            datum.isKYCVerified ?
                                                <ListItemSecondaryAction>
                                                    <Button onClick={() => {
                                                        setOpen(true);
                                                        setMode("RejectKYC");
                                                    }} color="default" variant="outlined" size='small' >Undo Verify</Button>
                                                </ListItemSecondaryAction> :
                                                datum.kycNo ?
                                                    <ListItemSecondaryAction>
                                                        <Button onClick={() => {
                                                            setOpen(true);
                                                            setMode("ApproveKYC");
                                                        }} color="primary" variant="contained" size='small' >Verify Now</Button>
                                                    </ListItemSecondaryAction> :
                                                    <></>
                                        }
                                    </ListItem>
                                </List>
                            </Grid>
                            {
                                datum.kycPath && <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex" justifyContent="center">
                                    <img src={datum.kycPath} width={320} />
                                </Grid>
                            }
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <List>
                                    {
                                        datum.wallets && datum.wallets.map((s) => {
                                            return <Card variant="outlined" className="mb-2 cursor">
                                                <CardHeader
                                                    title={s.address}
                                                    subheader={s.currency}
                                                    action={
                                                        <FileCopy color="primary"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                navigator.clipboard.writeText(s.address);
                                                                toastr.success("Copied Address to clipboard");
                                                            }} />
                                                    }
                                                    avatar={<Avatar src={getLogo(s.currency).logo} >
                                                    </Avatar>} />
                                            </Card>
                                        })
                                    }
                                </List>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }
            <Grid container spacing={2} className="my-2">
                {
                    data.length === 0 && !isLoading ?
                        <Grid xs={12} sm={12}>
                            {
                                data.length === 0 &&
                                <Box display={"flex"} height="60vh" flexDirection={"column"} justifyContent={"center"} alignItems={"center"} className="my-2">
                                    <img src={process.env.PUBLIC_URL + "/images/noData.svg"} width={"250"} />
                                </Box>
                            }
                        </Grid> :

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <MUIDataTable
                                title={'Member List'}
                                data={data}
                                columns={columns}
                                options={{
                                    filterType: 'checkbox',
                                    selectableRows: 'none',
                                    rowsPerPageOptions: [10],
                                }}
                            />
                        </Grid>
                }
            </Grid>
        </div>
    );
}

export default MemberList;
