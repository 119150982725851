import HttpHelper from "../../helpers/httpHelper";
import { createWhitelistUrl, deleteWhitelistUrl, getWhitelistUrl, updateStatusWhitelistUrl, updateWhitelistUrl } from "./whitelist.uri";

class WhitelistAPI {
    static async GetWhitelists(id) {
        let url = getWhitelistUrl(id);
        return HttpHelper.httpRequest(url, 'GET', {});
    }
}

export default WhitelistAPI;
