import { call, put, takeEvery } from 'redux-saga/effects';
import * as OrderActions from './order.actions';
import OrderAPI from './order.api';
import { OrderActionTypes } from './order.model';

export function* handleFetchOrder(action) {
    try {
        if (action.payload) {
            const response = yield call(OrderAPI.GetOrders, action.payload);
            yield put(OrderActions.fetchOrderSuccess(response));
        }
        else {
            yield put(OrderActions.fetchOrderSuccess([]));
        }
    } catch (e) {
        yield put(OrderActions.fetchOrderFailure(e));
    }
}

export function* handleUpdateOrder(action) {
    try {
        const response = yield call(OrderAPI.UpdateOrder, action.payload);
        yield put(OrderActions.updateOrderSuccess(response));
    } catch (e) {
        yield put(OrderActions.updateOrderFailure(e));
    }
}

export function* OrderSaga() {
    yield takeEvery(OrderActionTypes.FETCH_ORDER_REQUEST, handleFetchOrder);
    yield takeEvery(OrderActionTypes.UPDATE_ORDER_REQUEST, handleUpdateOrder);
}
