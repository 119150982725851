import { Button, Dialog, DialogContent, Grid, TextField } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSettingRequest, updateSettingRequest } from "../../store/configuration/configuration.actions";
import { getConfigurationLoading, getSetting } from "../../store/configuration/configuration.selector";
import TitleBar from "../base/TitleBar";
import Loader from "../base/loader";
import VerifyPin from "../verify/verifyPin";

function Configuration() {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [walletConfig, setWalletConfig] = useState([
        {
            "withdrawCommission": 1,
            "coinType": "BTC",
            "depositCommission": 1,
            "excludedTokens": [],
            "tokenCommission": 1,
            "orderCommission": 1
        },
        {
            "coinType": "BNB",
            "withdrawCommission": 1,
            "depositCommission": 1,
            "excludedTokens": [],
            "tokenCommission": 2,
            "orderCommission": 1
        },
        {
            "coinType": "TRX",
            "withdrawCommission": 1,
            "depositCommission": 1,
            "excludedTokens": [],
            "tokenCommission": 1,
            "orderCommission": 1
        },
        {
            "withdrawCommission": 1,
            "coinType": "XRP",
            "depositCommission": 1,
            "excludedTokens": [],
            "tokenCommission": 1,
            "orderCommission": 1
        },
        {
            "withdrawCommission": 1,
            "coinType": "ETH",
            "depositCommission": 1,
            "excludedTokens": [],
            "tokenCommission": 1,
            "orderCommission": 1
        },
        {
            "withdrawCommission": 1,
            "coinType": "LTC",
            "depositCommission": 1,
            "excludedTokens": [],
            "tokenCommission": 1,
            "orderCommission": 1
        }
    ]);

    const { setting, isLoading } = useSelector((state) => {
        return {
            setting: getSetting(state),
            isLoading: getConfigurationLoading(state)
        }
    });

    useEffect(() => {
        dispatch(fetchSettingRequest())
    }, []);

    useEffect(() => {
        setWalletConfig(setting.walletConfig);
    }, [setting]);

    const validate = () => {
        return true;
    }

    const handleSave = () => {
        if (validate()) {
            setOpen(true);
        }
    }

    return (
        <div className="mt-2">
            <Dialog open={open}>
                <DialogContent>
                    <VerifyPin
                        onClose={() => {
                            setOpen(false);
                        }}
                        isNotVerify={true}
                        onComplete={(code) => {
                            dispatch(updateSettingRequest({
                                walletConfig: walletConfig,
                                code: code
                            }));
                            setOpen(false);
                        }} />
                </DialogContent>
            </Dialog>
            <TitleBar heading="Configuration" secondary={"Settings"}
                icon={<Settings />}
            />
            {
                isLoading && <Loader />
            }
            <Grid container spacing={2} className="my-2">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={2} >
                        <Grid item xs={4} sm={4} md={2} lg={2}>
                            Coin Type
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2}>
                            Deposit Commission
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2}>
                            Withdraw Commis...
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2}>
                            Token Commission
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2}>
                            Order Commission
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2}>
                            Excluded Tokens
                        </Grid>
                    </Grid>
                    {
                        walletConfig.map((t) => {
                            return <Grid container spacing={2} >
                                <Grid item xs={4} sm={4} md={2} lg={2}>
                                    <TextField value={t.coinType} disabled variant="outlined" fullWidth />
                                </Grid>
                                <Grid item xs={4} sm={4} md={2} lg={2}>
                                    <TextField value={t.depositCommission} type="number" onChange={(e) => {
                                        let items = walletConfig.map((s) => {
                                            if (s.coinType === t.coinType) {
                                                s.depositCommission = parseFloat(e.target.value);
                                            }
                                            return s;
                                        });
                                        setWalletConfig(items);
                                    }} variant="outlined" fullWidth />
                                </Grid>
                                <Grid item xs={4} sm={4} md={2} lg={2}>
                                    <TextField value={t.withdrawCommission} type="number" onChange={(e) => {
                                        let items = walletConfig.map((s) => {
                                            if (s.coinType === t.coinType) {
                                                s.withdrawCommission = parseFloat(e.target.value);
                                            }
                                            return s;
                                        });
                                        setWalletConfig(items);
                                    }} variant="outlined" fullWidth />
                                </Grid>
                                <Grid item xs={4} sm={4} md={2} lg={2}>
                                    <TextField value={t.tokenCommission} type="number" onChange={(e) => {
                                        let items = walletConfig.map((s) => {
                                            if (s.coinType === t.coinType) {
                                                s.tokenCommission = parseFloat(e.target.value);
                                            }
                                            return s;
                                        });
                                        setWalletConfig(items);
                                    }} variant="outlined" fullWidth />
                                </Grid>
                                <Grid item xs={4} sm={4} md={2} lg={2}>
                                    <TextField value={t.orderCommission} type="number" onChange={(e) => {
                                        let items = walletConfig.map((s) => {
                                            if (s.coinType === t.coinType) {
                                                s.orderCommission = parseFloat(e.target.value);
                                            }
                                            return s;
                                        });
                                        setWalletConfig(items);
                                    }} variant="outlined" fullWidth />
                                </Grid>
                                <Grid item xs={4} sm={4} md={2} lg={2}>
                                    <TextField value={t.excludedTokens.join(",")} type="number" onChange={(e) => {
                                        let items = walletConfig.map((s) => {
                                            if (s.coinType === t.coinType) {
                                                s.excludedTokens = e.target.value.split(",");
                                            }
                                            return s;
                                        });
                                        setWalletConfig(items);
                                    }} variant="outlined" fullWidth />
                                </Grid>
                            </Grid>
                        })
                    }

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button onClick={() => handleSave()} variant="contained" color="primary" className="float-right">Save</Button>
                </Grid>
            </Grid>
        </div>
    );
}

export default Configuration;
