import { action } from "typesafe-actions";
import { OrderActionTypes } from "./order.model";

export const fetchOrderRequest = (req) => action(OrderActionTypes.FETCH_ORDER_REQUEST, req);
export const fetchOrderSuccess = (res) => action(OrderActionTypes.FETCH_ORDER_SUCCESS, res);
export const fetchOrderFailure = (err) => action(OrderActionTypes.FETCH_ORDER_FAILURE, err);

export const updateOrderRequest = (req) => action(OrderActionTypes.UPDATE_ORDER_REQUEST, req);
export const updateOrderSuccess = (res) => action(OrderActionTypes.UPDATE_ORDER_SUCCESS, res);
export const updateOrderFailure = (err) => action(OrderActionTypes.UPDATE_ORDER_FAILURE, err);


