import { action } from "typesafe-actions";
import { ConfigurationActionTypes } from "./configuration.model";

export const fetchSettingRequest = (req) => action(ConfigurationActionTypes.FETCH_SETTING_REQUEST, req);
export const fetchSettingSuccess = (res) => action(ConfigurationActionTypes.FETCH_SETTING_SUCCESS, res);
export const fetchSettingFailure = (err) => action(ConfigurationActionTypes.FETCH_SETTING_FAILURE, err);

export const updateSettingRequest = (req) => action(ConfigurationActionTypes.UPDATE_SETTING_REQUEST, req);
export const updateSettingSuccess = (res) => action(ConfigurationActionTypes.UPDATE_SETTING_SUCCESS, res);
export const updateSettingFailure = (err) => action(ConfigurationActionTypes.UPDATE_SETTING_FAILURE, err);


