import { call, put, takeEvery } from 'redux-saga/effects';
import * as WhitelistActions from './whitelist.action';
import WhitelistAPI from './whitelist.api';
import { WhitelistActionTypes } from './whitelist.model';

export function* handleFetchWhitelist(action) {
    try {
        if (action.payload) {
            const response = yield call(WhitelistAPI.GetWhitelists, action.payload);
            yield put(WhitelistActions.fetchWhitelistSuccess(response));
        }
        else {
            yield put(WhitelistActions.fetchWhitelistSuccess([]));
        }

    } catch (e) {
        yield put(WhitelistActions.fetchWhitelistFailure(e));
    }
}

export function* WhitelistSaga() {
    yield takeEvery(WhitelistActionTypes.FETCH_WHITELIST_REQUEST, handleFetchWhitelist);
}
