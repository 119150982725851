import { call, put, takeEvery } from 'redux-saga/effects';
import * as MemberActions from './member.action';
import MemberAPI from './member.api';
import { MemberActionTypes } from './member.model';
import Swal from 'sweetalert2';

export function* handleFetchMember(action) {
    try {
        const response = yield call(MemberAPI.GetMembers, action.payload);
        yield put(MemberActions.fetchMemberSuccess(response));
    } catch (e) {
        yield put(MemberActions.fetchMemberFailure(e));
    }
}

export function* handleActivateMember(action) {
    try {
        const response = yield call(MemberAPI.ActivateUser, action.payload);
        yield put(MemberActions.activateMemberRequest(response));
        Swal.fire({
            title: "Member",
            text: `Member Activated successfully`,
            icon: "success"
        })
    } catch (e) {
        yield put(MemberActions.activateMemberFailure(e));
    }
}

export function* handleSuspendMember(action) {
    try {
        const response = yield call(MemberAPI.SuspendUser, action.payload);
        yield put(MemberActions.suspendMemberSuccess(response));
        Swal.fire({
            title: "Member",
            text: `Member Suspended successfully`,
            icon: "success"
        })
    } catch (e) {
        yield put(MemberActions.suspendMemberFailure(e));
    }
}

export function* handleApproveKYC(action) {
    try {
        const response = yield call(MemberAPI.ApproveKYC, action.payload);
        yield put(MemberActions.approveKYCSuccess(response));
        Swal.fire({
            title: "KYC Update",
            text: `Member KYC Approved successfully`,
            icon: "success"
        });
    } catch (e) {
        yield put(MemberActions.approveKYCFailure(e));
    }
}

export function* handleRejectKYC(action) {
    try {
        const response = yield call(MemberAPI.RejectKYC, action.payload);
        yield put(MemberActions.rejectKYCSuccess(response));
        Swal.fire({
            title: "KYC Update",
            text: `Member KYC Rejected successfully`,
            icon: "success"
        });
    } catch (e) {
        yield put(MemberActions.rejectKYCFailure(e));
    }
}

export function* MemberSaga() {
    yield takeEvery(MemberActionTypes.FETCH_MEMBER_REQUEST, handleFetchMember);
    yield takeEvery(MemberActionTypes.ACTIVATE_MEMBER_REQUEST, handleActivateMember);
    yield takeEvery(MemberActionTypes.SUSPEND_MEMBER_REQUEST, handleSuspendMember);
    yield takeEvery(MemberActionTypes.APPROVE_KYC_REQUEST, handleApproveKYC);
    yield takeEvery(MemberActionTypes.REJECT_KYC_REQUEST, handleRejectKYC);
}
