import { call, put, takeEvery } from 'redux-saga/effects';
import * as ConfigurationActions from './configuration.actions';
import ConfigurationAPI from './configuration.api';
import { ConfigurationActionTypes } from './configuration.model';
import Swal from 'sweetalert2';

export function* handleFetchSetting() {
    try {
        const response = yield call(ConfigurationAPI.GetSetting);
        yield put(ConfigurationActions.fetchSettingSuccess(response));
    } catch (e) {
        yield put(ConfigurationActions.fetchSettingFailure(e));
    }
}

export function* handleUpdateSetting(action) {
    try {
        const response = yield call(ConfigurationAPI.UpdateSetting, action.payload);
        yield put(ConfigurationActions.updateSettingSuccess(response));
        Swal.fire({
            title: "Configuration",
            text: "Configuration Updated Successfully",
            icon: "success"
        })
    } catch (e) {
        yield put(ConfigurationActions.updateSettingFailure(e));
    }
}

export function* ConfigurationSaga() {
    yield takeEvery(ConfigurationActionTypes.FETCH_SETTING_REQUEST, handleFetchSetting);
    yield takeEvery(ConfigurationActionTypes.UPDATE_SETTING_REQUEST, handleUpdateSetting);
}
