export const API_URL = process.env.REACT_APP_API_URL;

export function getSettingUrl() {
    return API_URL + '/setting';
}

export function updateSettingUrl(code) {
    return 'http://127.0.0.1:5001/test-wallet-payment/us-central1/cryptoAdminService/api/v1/setting?code=' + code;
}


