export const API_URL = process.env.REACT_APP_API_URL;

export function getMemberUrl() {
    return API_URL + '/member';
}

export function activateUserUrl(req) {
    return API_URL + '/member/activate/' + req.id + '?code=' + req.code;
}

export function suspendUserUrl(req) {
    return API_URL + '/member/suspend/' + req.id + '?code=' + req.code;
}

export function approveKYCUrl(req) {
    return API_URL + '/member/approve/kyc/' + req.id + '?code=' + req.code;
}

export function rejectKYCUrl(req) {
    return API_URL + '/member/reject/kyc/' + req.id + '?code=' + req.code;
}